import React from 'react'
import Banner from './Banner'

const Termos = () => {
  return (
    <section>
      <Banner name="Termos de Uso"/>
      <div className='termos container py-4'>
        <h2>
          ACEITAÇÃO DE USO
        </h2>
        <p>
          Esta página de vendas é oferecida ao usuário, condicionado à aceitação dos termos, condições e avisos contidos aqui, sem modificações. O responsável pela mesma poderá modificar o conteúdo, formato, acabar com a sua operação ou suas ferramentas a seu critério único e sem aviso prévio. Os links apontados não estão sob o controle desta página de vendas, ou seja, não somos responsáveis pelo conteúdo de qualquer outro website indicado ou acessado por meio deste e reserva-se o direito de eliminar qualquer conteúdo sem aviso prévio. O acesso do usuário e o uso desta página de vendas constitui aceitação de todos os termos e condições abaixo:
        </p>

        <h2>
          NAVEGAÇÃO
        </h2>
        <p>
        O visitante poderá usar este site apenas para finalidades lícitas. Este espaço não poderá ser utilizado para publicar, enviar, distribuir ou divulgar conteúdo ou informação de caráter difamatório, obsceno ou ilícito, inclusive informações de propriedade exclusiva pertencentes a outras pessoas ou empresas, bem como marcas registradas ou informações protegidas por direitos autorais, sem a expressa autorização do detentor desses direitos. O site poderá, mas não é obrigado, a monitorar, revistar e restringir o acesso a qualquer área no site, em especial, onde usuários transmitem e trocam informações entre si, incluindo, mas não limitado a salas de chat, centro de mensagens ou outros fóruns de debates.
        </p>

        <h2>
          ISENÇÃO DE RESPONSABILIDADE
        </h2>
        <p>
        O site empenha-se em manter a qualidade, atualidade e autenticidade das informações, mas não se responsabiliza por eventuais falhas nos serviços ou inexatidão das informações oferecidas. Reserva-se o direito de alterar as condições de uso, informações ou preços dos produtos oferecidos no site a qualquer momento, sem aviso prévio. O usuário aceita e declara compreender que, em razão do peculiar ambiente da Internet, este site não poderá garantir que o acesso seja livre de erros ou problemas decorrentes de casos fortuitos, internos ou externos, casos de força maior ou ainda de outros casos não inteiramente sujeitos a controle direto dos administradores do site, e portanto o usuário se obriga a isentar o mesmo de quaisquer reclamações ou indenizações. Nesse sentido, o site também não se responsabiliza por interrupções, interceptações, invasões, disseminação de vírus ou outros atos ilícitos, típicos e atípicos de ambiente virtual, e de web, dos quais o site não tenha tido intenção deliberada de participar ou praticar.
        </p>

        <h2>
          LIMITAÇÃO DE RESPONSABILIDADE
        </h2>
        <p>
        O site não poderá ser responsabilizado, em nenhum caso, por quaisquer danos diretos, indiretos, casuais e especiais relacionados ou derivados deste site ou de seu uso, ou de qualquer site ou recurso vinculado, informação referenciada ou acessada por meio do mesmo. Os materiais são fornecidos neste site sem nenhuma garantia explícita ou implícita de comercialização ou adequação a qualquer objetivo específico. Em nenhum caso esta página pode ser responsabilizada por quaisquer danos, incluindo lucros cessantes, interrupção de negócio, ou perda de informação que resultem do uso ou da incapacidade de uso do mesmo. A página ainda não se responsabiliza pela violação de direitos autorais decorrente de informações, documentos e materiais publicados comprometendo-se a retirá-los do ar assim que notificado da infração.
        </p>

        <h2>
          LINKS PARA SITES DE TERCEIROS
        </h2>
        <p>
        Esta página não está sob o controle do conteúdo de qualquer outro website indicado ou acessado por meio deste e reserva-se o direito de eliminar o link ou direcionamento a qualquer momento, sem aviso prévio.
        </p>
        <h2>
          DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL
        </h2>
        <p>
        As informações, conteúdos e criações desta página de vendas pertencem aos seus criadores e colaboradores. Não podem ser copiados, reproduzidos, modificados, publicados, atualizados, postados, transmitidos ou distribuídos de qualquer maneira sem autorização prévia. As imagens e vídeos contidos neste website são aqui incorporadas apenas para fins de visualização, e, compreensão do produto. O nome do produto, rótulo, o nome de domínio para acesso na Internet, bem como todos os elementos característicos da tecnologia desenvolvida e aqui apresentada, sob a forma da articulação de bases de dados, constituem marcas registradas e propriedades intelectuais privadas e todos os direitos decorrentes de seu registro são assegurados por lei. As marcas registradas só podem ser usadas publicamente com autorização expressa. Toda e qualquer violação de direitos autorais deverá ser notificada ao suporte do produto, descrito abaixo da página de vendas.
        </p>
        <h2>
          LEIS APLICÁVEIS
        </h2>
        <p>
        O acesso ao site é proibido em territórios onde o conteúdo seja considerado ilegal. Aqueles que optarem por acessar este site a partir de outras localidades o farão por iniciativa própria e serão responsáveis pelo cumprimento das leis locais aplicáveis. Os materiais não deverão ser usados ou exportados em descumprimento das leis brasileiras sobre exportação. Qualquer pendência com relação aos materiais será avaliada diante das leis brasileiras. O acesso e uso a este site representa a aceitação expressa e irrestrita dos termos de uso acima descritos.
        </p>
      </div>
    </section>
  )
}

export default Termos